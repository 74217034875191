// import TriviaGame from "./TheGames/Trivia/Trivia"
import TriviaGame from "./TheGames/Trivia/Trivia"
// import ShoppingGame from "./TheGames/shopping/shopping"
import ShoppingGame from "./TheGames/shopping/shopping"

const gameList = {
    "1":TriviaGame,
    "2":ShoppingGame,
}

export default gameList